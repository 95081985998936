/* -------------------------------basic ------------------------------------ */
*,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body .modal-open {
  overflow: hidden;
  padding-right: 0px !important;
  /* padding-right: -100px!important; */
}

.modal {
  padding-right: 0;
}

:root {
  --primary-color: red;
  --secondry-color: blue;
  --tertiary-color: yellow;
  --fourth-color: green;
  --fifth-color: orange;
  --primary-font: 'Salsa', cursive;
  --secondry-font: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


body.offcanvas-start {
  overflow: auto;
}

body {
  padding-right: 0!important;
  font-family: var(--primary-font);
  font-display: swap;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}

/* Track */
::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, yellow 0%, red 80%);
  border-radius: 10px;
  /* roundness of the scrollbar handle */
}

.infoIcon {
  cursor: pointer;
  width: 20px;
}

.secondry-font {
  font-family: var(--secondry-font);
  font-weight: 500;
}

.row {
  align-items: center;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: normal;
  margin-bottom: 0;
  overflow: visible;
}

del {
  color: #0000006b;
  text-decoration: none;
}
/* for headings */
.h4 {
  /* font-size: 1.28rem !important; */
  font-size: 1.38rem !important;
}

/* for paragraph  */
.h5 {
  /* font-size: 1.03rem !important; */
  font-size: 1.11rem !important;

}

/* input, small text, message */
.h6 {
  /* font-size: 0.958rem !important; */
  font-size: 1.07rem !important
}



@media screen and (min-width: 990px) {
  .h4 {
    /* font-size: 1.28rem !important; */
    font-size: 1.33rem !important;
  }

  .h5 {
    /* font-size: 1.03rem !important; */
    font-size: 1.15rem !important;
  
  }
  .h6 {
    /* font-size: 0.93rem !important; */
    font-size: 1.09rem !important;
  }
}

/* .h5{
      font-size: 1rem!important;
    }
    
    .h4{
      font-size: 1.2rem!important;
    }
} */


@media screen and (max-width: 577px) {
  .o-1 {
    order: 1 !important;
  }

  .o-2 {
    order: 2 !important;
  }

  .o-3 {
    order: 3 !important;
  }

  .o-4 {
    order: 4 !important;
  }

  .o-5 {
    order: 5 !important;
  }

  .o-6 {
    order: 6 !important;
  }

  .o-7 {
    order: 7 !important;
  }

  .o-8 {
    order: 8 !important;
  }

  .o-9 {
    order: 9 !important;
  }
}

@media screen and (max-width: 768px) {
  .o-sm-1 {
    order: 1;
  }

  .o-sm-2 {
    order: 2;
  }

  .o-sm-3 {
    order: 3;
  }

  .o-sm-4 {
    order: 4;
  }

  .o-sm-5 {
    order: 5;
  }

  .o-sm-6 {
    order: 6;
  }

  .o-sm-7 {
    order: 7;
  }

  .o-sm-8 {
    order: 8;
  }

  .o-sm-9 {
    order: 9;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  .printable,
  .printable * {
    visibility: visible;
  }
}


th {
  padding: 4px 0px;
}

td {
  padding: 4px 0px;
}

tr:hover {
  background-color: rgba(0, 128, 0, 0.187)!important;
}

.heading {
  color: var(--primary-color);
  text-align: center;
  margin: 20px auto;
  text-decoration: underline;
}


.primary-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 6.5px 0px;
  margin: auto;
  font-weight: lighter !important;
  border-radius: 20px 20px 20px 20px;
  color: var(--primary-color);
  background-color: var(--secondry-color);
  border: 2.5px solid transparent;
  transition: all 0.5s ease-in;
}

.primary-button svg {
  fill: var(--primary-color);
  transition: all .5s ease-in;
}

.primary-button:hover svg {
  fill: var(--secondry-color);
  transition: all .5s ease-in;
}

.primary-button:hover {
  color: var(--secondry-color);
  background-color: transparent;
  border: 2.5px solid var(--primary-color);
  transition: all .5s ease-in;
}


.secondry-button {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 6.5px 0;
  margin: auto;
  border-radius: 20px;
  color: var(--secondry-color);
  background-color: transparent;
  border: 2.5px solid var(--primary-color);
  z-index: 1;
  transition: all 0.5s ease-in-out;

}

.secondry-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: var(--secondry-color);
  color: red;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}

.secondry-button:hover::before {
  width: 100%;
}

.secondry-button:hover {
  color: var(--primary-color);
}

.secondry-button svg {
  fill: var(--secondry-color);
  transition: all .5s ease-in-out;
}

.secondry-button:hover svg {
  fill: var(--primary-color);
  transition: all .5 ease-in-out;
}

i {
  font-weight: bolder !important;
}


@keyframes moving {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(0%);
  }
}


.divider {
  display: flex;
  align-items: center;
}

.divider::before,
.divider::after {
  flex: 1;
  content: '';
  padding: 1px;
  background-color: gray;
  margin: 5px;
}

select {
  padding: 3px 0px;
  width: 100%;
}

option {
  width: 100%;
}

label {
  margin-bottom: 5px;
  fill : rgb(114, 114, 114);
  color: rgb(114, 114, 114);
}

input,
textarea,
.form-select {
  border: none;
  outline: none;
  /* Remove default focus outline */
  width: 100%;
}

input,
textarea,
.form-select {
  border-bottom: 2px solid grey;
  padding-bottom: 7px;
  caret-color: var(--primary-color);
}

input:focus,
textarea:focus,
.form-select:focus {
  transition: all 0.2s ease-in;
  border-bottom: 2.17px solid var(--primary-color);
}

select {
  border: 1px solid black
}

textarea {
  resize: vertical !important;
}

input::placeholder,
textarea::placeholder {
  color: rgba(12, 12, 12, 0.203);
  /* font-weigh/t: 600; */
  /* font-family: 'Eczar', serif; */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the arrows in Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* Remove default focus styles on select */
.form-select:focus {
  box-shadow: none;
  border-color: var(--primary-color);
}

.fa-xmark {
  background-color: white;
}

svg {
  overflow: visible!important;
  display: flex;
  width: 20px;
  cursor: pointer;
  transition: all .5s ease-in-out;
}



@media screen and (min-width: 50px) {
  .my-container {
    max-width: 87%;
    margin: auto
  }
}

@media screen and (min-width: 594px) {
  .my-container {
    max-width: 580px;
    margin: auto;
  }
}

@media screen and (min-width : 768px) {
  .my-container {
    max-width: 720px;
    margin: auto;
  }
}

@media screen and (min-width : 992px) {
  .my-container {
    max-width: 970px;
    margin: auto;
  }
}

@media screen and (min-width : 1200px) {
  .my-container {
    max-width: 1140px;
    margin: auto;
  }
}

section {
  min-height: 75vh;
  /* margin-top: 60px !important; */
  margin-top: 63px !important;
  margin-bottom: 40px !important;
}

.searchlist {
  position: relative;
  overflow: visible;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.searchlistinput {
  position: relative;
  width: 300px;
}

.searchlistdiv {
  background-color: white;
  z-index: 10;
  position: absolute;
  top: 28px;
  border: 2px solid var(--primary-color);
  width: 320px;
  margin-top: 10px;
  border-radius: 10px;
  max-height: 270px;
  transition: all .6s ease-in-out;
}

.searchlistvalue .h6 {
  padding: 5px 10px;
  color: blue;
  cursor: pointer;
}

.searchlistvalue .h6:hover {
  color: red;
  transition: all .8s ease-in-out;
}

.customWeight {
  border-bottom: 2.2px solid goldenrod;
  font-size: 14px;
  padding: 4px 6px 8px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  width: 100%;
  background-color: #ffffc7;
  border-radius: 20px;
  transition: all .5s ease-in-out;
  z-index: 5;
}

.customWeight div {
  overflow: visible;
}

.customWeight span {
  color: red;
}

.customWeight input {
  background-color: transparent;
}

.customWeight .crossIcon {
  position: absolute;
  top: 10px;
  right: 15px;
  fill: gray;
  cursor: pointer;
}

.customWeightIcon {
  width: 26px;
}

.customWeight.show {
  transform: translateY(0%);
  transition: all .5s ease-in-out;
}

/* --------------------------------------------message-----------------------------------------------  */
.message {
  padding: 10px 15px;
  width: fit-content;
  border-radius: 10px;
  position: fixed;
  /* top: 90vh; */
  bottom: 35px;
  left: 5vw;
  z-index: 2;
}


@media screen and (max-width: 572px) {
  .message {
    /* top: 87vh; */
    bottom: 80px;
  }
}


tr,
th,
td {
  border: 2px solid rgba(128, 128, 128, 0.705);
  text-align: center
}

.socketSuccessMessage {
  border-radius: 7px;
  position: absolute;
  top: 85px;
  left: 12px;
  border: 1px solid green;
  padding: 5px 15px;
  background-color: #c8ffc8;
  overflow: hidden;
}



/* -------------------------------------------header---------------------------------------------- */
.nav {
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: var(--tertiary-color);
  padding: 10px 0px;
  margin-top: 0px;
  top: 0;
  left: 0;
}

.nav .link {
  color: var(--primary-color);
  transition: all .5s ease-in-out;
  display: flex;
  align-items: center;
  gap: 15px;
  flex: none;
  overflow: hidden
}

.nav .link .link-icon {
  height: 35px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--primary-color)
}

.nav .link .link-icon svg {
  width: 17px;
  fill: var(--primary-color);
}



.nav .link:hover,
.nav .link.active {
  color: var(--secondry-color);
}

.nav .link:hover .link-icon svg,
.nav .link.active .link-icon svg {
  fill: var(--secondry-color);
}


.header {
  display: flex;
  justify-content: space-between;
}

.nav .navLink {
  overflow: visible;
  position: relative;
  color: var(--secondry-color)!important;
  transition: all .5s ease-in-out;
}

.nav .navLink::before {
  content: '';
position: absolute;
height: 2.5px;
width: 0%;
background-color: var(--primary-color);
top : 23px ;
transition: all .5s ease-in-out;
}

.nav .navLink:hover, .nav .navLink.active {
  /* text-decoration: underline; */
  color: var(--primary-color)!important;
  transition: all .5s ease-in-out;
}

.nav .navLink:hover::before, .nav .navLink.active::before {
  transition: all .5s ease-in-out;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .header {
    padding-left: 2px;
    padding-right: 0px;
  }
}

/* upper change */

.navbar-brand {
  font-weight: 500;
  color: var(--primary-color);
  transition: all .5s ease-in-out;
  padding-right: 10px;
  margin-right: 3px;
  font-size: 1.4rem !important;
  /* padding idhar change */
}

.navbar-brand:hover {
  color: var(--secondry-color);
}

.nav .menuIcon {
  fill: var(--secondry-color);
  transition: all .5s ease-in-out;
  margin-right: 7px;
  width: 23px;
}

.nav .menuIcon:hover {
  fill: var(--primary-color);
}

.offcanvas-header {
  padding: 10px 1rem;
  overflow: hidden;
}

.offcanvas-start {
  opacity: 0;
}

.offcanvas {
  transition: all .5s ease-in-out;
  width: 270px;
}

.show {
  opacity: 1;
  visibility: visible;
}

.offcanvas-body {
  display: flex;
  padding: 2rem 20px;
  flex-direction: column;
  gap: 20px;
  scrollbar-width: none;
}

.offcanvas-body .snsinput {
  color: var(--primary-color);
}

.nav-search {
  background-color: transparent;
  color: var(--primary-color);
}

.nav-user-button {
  border: 2.5px solid var(--primary-color);
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border-radius: 50%;
}

.nav-user-button i {
  font-size: 20px;
  color: var(--secondry-color);
}

.list {
  display: none;
  justify-content: end;
  margin-right: 80px;
}

.list.show {
  display: flex !important;
}


.list .listdiv {
  position: fixed;
  width: 320px;
  z-index: 10;
  /* margin-top: 60px; */
  margin-top: 64px;
  border: 2px solid rgba(255, 0, 0, 0.265);
  background-color: var(--tertiary-color);
  max-height: 250px;
}

.productSearchingImg .lazy-load-image-background {
  width: auto;
}

.list .listdiv a {
  display: flex;
  align-items: center;
  padding: 5px 2px 0px;
}

.list .listdiv img{
  flex: none;
   height: 40px;
   width: 45px;
}

.list .listdiv h5 {
  color: var(--primary-color);
  padding: 0px;
  cursor: pointer;
  transition: all .6s ease-in-out;
}

.list .listdiv h5:hover {
  color: var(--secondry-color);
}

@media screen and (max-width: 768px) {
  .list {
    margin-right: 82px;
  }
}


.profile-dropdown .list {
  display: none;
  justify-content: end;
  margin-right: -20px;
  z-index: 2;
}

.profile-dropdown .list.show {
  display: flex;
}

.profile-dropdown .list div {
  position: fixed;
  width: 90px;
  z-index: 10;
  /* margin-top: 60px; */
  margin-top: 64px !important;
  border: 2px solid rgba(255, 0, 0, 0.265);
  background-color: var(--tertiary-color);
}

.profile-dropdown .list div .h6 {
  padding: 6px;
  cursor: pointer;
  color: var(--primary-color);
  transition: all .6s ease-in-out;
}

.profile-dropdown .list div .h6:hover {
  color: var(--secondry-color);
}

.offcanvas-list {
  display: none;
  border: 2px solid rgba(255, 0, 0, 0.265);
  max-height: 250px;
  width: 240px;
}

.offcanvas-list.show {
  display: flex;
}

.offcanvas-list .listdiv {
  width: 100%;
}

.offcanvas-list .listdiv .h6 {
  color: var(--primary-color);
  width: 100%;
  padding: 10px 5px;
  cursor: pointer;
  transition: all .6s ease-in-out;
}

.offcanvas-list .listdiv .h5:hover {
  color: var(--secondry-color);
}

@media screen and (max-width: 576px) {
  .product-list .list {
    margin-right: 34px;
  }

  .profile-dropdown .list {
    position: fixed;
    right: 23px;
  }
}


@media screen and (min-width: 576px) {
  .offcanvas-list {
    display: none !important;
  }
}

/* ---------------------------------------bottomTab -------------------------------------------------- */
.bottomTab {
  border-top: 2.5px solid orange;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 90;
}

.bottomTab .link {
  overflow: visible;
  display: flex;
  width: 55px;
  height: 45px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.bottomTab .link span {
  font-size: 12px;
  transition: all .5s ease-in-out;
}

.bottomTab .link svg {
  fill: #ffc459;
  transition: all .5s ease-in-out;
}

.bottomTab .link.active svg, 
.bottomTab .link:hover svg {
  fill: orangered;
  transition: all .5s ease-in-out;
}

.bottomTab .link.active span, 
.bottomTab .link:hover span{
  color: orangered;
  transition: all .5s ease-in-out;
}

.bottomTab .link .itemCount {
   color: inherit;
   position: absolute;
   top: -8px;
   right: 2px;
   color: orangered;
   font-size: 12.5px;
   font-weight: bold;
}

/* --------------------------------------------footer -------------------------------------------------- */
.footer {
  background-color: var(--secondry-color);
  color: white;
  padding: 10px 0px;
}

.footer-head {
  text-decoration: underline;
  margin-bottom: 10px;
  color: var(--tertiary-color);
}

.footer-link {
  fill: white;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all .5s ease-in-out;
}

.footer-link:hover {
  transform: translateX(10px);
  color: var(--tertiary-color);
}

.footer-link svg {
  width: 14px;
}

@media screen and (max-width: 576px) {
    .footer {
      margin-bottom: 57px;
    }
}

/* --------------------------------------------user pages ----------------------------------------------- */
.login,
.create,
.forgot,
.profile {
  display: flex;
  justify-content: center;
  align-items: center;
}


.create .logoside svg {
  fill: red;
  transition: all .5s ease-in-out;
}

.create .logoside svg:hover {
  fill: blue;
  transition: all .5s ease-in-out;
}

.login .login-card,
.create .create-card,
.forgot .forgot-card {
  width: 100%;
  margin: auto;
  border: none;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 20px;
  box-shadow: 5px 7px 25px -10px;
}

.login-card .primary-button,
.create-card .primary-button,
.forgot-card .primary-button,
.profile .primary-button {
  animation: moving 1s infinite;
}

.smalllogo {
  max-width: 400px;
}

.loadingmk {
  font-family: "Rock Salt", cursive;
  font-size: 50px;
  margin-left: 20px;
  overflow: hidden;
}

.loadingname {
  font-size: 38px;
  transform: translateY(-25px);
  overflow: hidden;
}

.loadingflex {
  align-items: center;
  height: 100px;
  transform: translateY(-40px);
  color: blue;
  width: 300px;
  justify-content: center;
}

.loadingflex svg {
  width: 70px;
  fill: blue;
  padding: 10px;
}

@media screen and (max-width : 768px) {

  .login .login-card,
  .create .create-card,
  .forgot .forgot-card {
    width: 80%;
  }

  .smalllogo {
    max-width: 300px;
  }
}


@media screen and (max-width : 576px) {

  .login .login-card,
  .create .create-card,
  .forgot .forgot-card {
    width: 95%;
    max-width: 400px;
  }
}

.review {
  display: flex;
  align-items: center;
}

.star-rating {
  display: flex;
  align-items: center;
}

.star {
  width: 11px;
  fill: #666464!important;
  /* Set the color for empty stars */
}

.filled {
  fill: #ffd700!important;
  /* Set the color for filled stars */
}

.half-filled {
  fill: #ffcc00!important;
  /* Set the color for half-filled stars */
}


/* ----------------------------------------notification page------------------------------------------ */
.notification {
  width: 60%;
}

@media screen and (max-width : 996px) {
  .notification {
    width: 75%;
  }
}


@media screen and (max-width : 768px) {
  .notification {
    width: 100%;
  }
}

/* -------------------------------------- order page ------------------------------------------------- */

.singleorder {
  border: 5px solid gray;
  border-radius: 20px;
  padding: 10px 12px;
  margin-bottom: 20px;
  position: relative;
}

.singleorder .primary-button {
  animation: moving 1s infinite;
}

.orderitem .orderimg {
  height: 90px;
  width: 100%;
  margin-left: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.orderitem .orderimg .lazy-load-image-background {
  display: contents!important;
}

.orderitem .orderimg img {
  height: 100%;
  width: 100%;
}

.singleorder .modal-body {
  padding: 0 5px 0 0;
}

.billTotalDetail {
  position: absolute;
  border: 1px solid black;
  padding: 6px;
  border-radius: 10px;
  bottom: 110%;
  min-width: 230px;
  background: white;
  left: -2px;
  z-index: 10;
  transition: all .5s ease-in-out;
}

.remainingDetail {
  position: absolute;
  border: 1px solid black;
  padding: 6px;
  border-radius: 10px;
  bottom: 110%;
  width: 185px;
  background: white;
  left: 0px;
  z-index: -1;
  transition: all .5s ease-in-out;
}

.remainingDetail span {
  float: right;
  color: green;
}

.billTotalDetail span {
  float: right;
  color: green;
}

.singleorder .toolbox {
  background-color: white;
  position: absolute;
  top: 5px;
  right: 5px;
  border: 2px solid gray;
  padding: 7px 11px;
  border-radius: 20px;
  display: flex;
  width: fit-content;
  align-items: center;
}

.singleorder i {
  flex: none;
}

.singleorder .updateIcon {
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 10px;
  fill: var(--primary-color);
  width: 18px;
  transition: all .5s ease-in-out;
}

.singleorder .updateIcon:hover {
  fill: var(--secondry-color);
  transition: all .5s ease-in-out;
}

.singleorder .toolbox .toolboxdata {
  display: flex;
  opacity: 0;
  transform: translateX(100px);
  width: 0;
  align-items: center;
  gap: 15px;
  transition: all 0.6s ease-out;
}

.singleorder .toolbox .toolboxdata.active {
  display: flex;
  width: fit-content;
  transform: translateX(0px);
  opacity: 1;
  transition: all 0.6s ease-in;
}

.singleorder .toolbox .fa-toolbox {
  transform: rotate(0deg);
  transition: all 0.4s ease-in-out;
}

.singleorder .toolbox .fa-toolbox.active {
  transform: rotate(360deg);
  transition: all 0.4s ease-in-out;
}

@media screen and (max-width: 576px) {
  .orderitem .orderimg {
    height: 100px;
  }
}

@media screen and (max-width : 450px) {
  .orderitem .orderimg {
    height: 90px;
  }
}




/* ------------------------------------------cart page ---------------------------------------- */
.mcartitem {
  position: relative;
  margin-bottom: 20px;
  border: 3px solid gray;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  transition: all 0.5s ease-in;
}

.mcartitem img {
  width: 220px !important;
  height: 140px !important;
}

.mcartitem:hover {
  box-shadow: 4px 5px 7px 3px gray;
}

.mcartitem:hover a {
  color: var(--primary-color);
}

.mcartitemdetail {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}



.plus,
.minus {
  display: flex;
  justify-content: center;
  width: 35px;
}

.plus svg,
.minus svg {
  width: 15px;
  fill: blue;
}

.plus:hover svg,
.minus:hover svg {
  fill: red;
  transition: all .5s ease-in-out;
}

.delete {
  display: flex;
  background-color: none;
  border: none;
  transition: all .5s ease-in-out;
}

.delete svg {
  fill: var(--primary-color);
}

.delete:hover {
  transform: scale(1.2);
  transition: all .5s ease-in-out;
}

.delete:hover svg {
  fill: var(--secondry-color);
  transition: all 0.5s ease-in-out
}



/* ---------------------------------------------category page---------------------------------------------- */

.singlecategory {
  border: 2px solid #00000037;
  border-radius: 20px;
  cursor: pointer;
  margin: 20px 12px 20px 0px;
  transition: all 0.5s ease-in;
}

.singlecategory:hover {
  box-shadow: 4px 3px 7px 6px #808080bd;
  transform: translateY(-10px);
}

.singlecategory:hover .secondry-button::before {
  width: 100%;
  z-index: -1;
}

.singlecategory:hover .secondry-button {
  color: var(--primary-color);
}

.singlecategory img {
  overflow: hidden!important;
  width: 100%;
  height: 250px;
  aspect-ratio: 1.5!important;
}

.singlecategorydetail {
  padding: 10px 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 576px) {
  .singlecategory {
    margin: 20px auto;
    width: 80%;
  }
}

@media screen and (max-width: 470px) {
  .singlecategory {
    width: 95%;
  }
}


/* ----------------------------------------------- category products page------------------------------- */
.singleproduct {
  position: relative;
  border: 2px solid rgba(128, 128, 128, 0.58);
  border-radius: 20px;
  cursor: pointer;
  margin: 20px 12px 20px 0px;
  transition: all 0.5s ease-in;
}

.singleproduct img {
  height: 250px;
  aspect-ratio: 1.5!important;
  overflow: hidden!important;
}

.singleproduct:hover {
  box-shadow: 4px 3px 7px 6px #808080bd;
  transform: translateY(-10px);
}

.singleproduct:hover .primary-button {
  animation: moving 1s infinite;
  font-weight: normal!important;
}

.singleproduct a {
  transition: all .5s ease-in-out;
}
.singleproduct:hover a {
  color: var(--primary-color);
  transition: all .5s ease-in-out;
}


.singleproduct .editicon {
  opacity: 1;
  cursor: pointer;
  transition: all .5s ease-in-out;
}


.singleproductdetail {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


@media screen and (max-width: 575px) {
  .singleproduct {
    margin: 20px auto;
    width: 75%;
  }
}

@media screen and (max-width: 470px) {
  .singleproduct {
    width: 96%;
  }
}

/* --------------------------------------------------detail product page -------------------------------- */
.detailproduct {
  position: relative;
  border-radius: 20px;
  cursor: pointer;
  margin: 20px 10px;
  transition: all 0.5s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  box-shadow: 4px 3px 9px 7px gray;
  max-width: 400px;
}


.detailproduct img {
  height: 220px;
  width: 100% !important;
  margin: 5px 0px;
  transition: all 0.5s ease-in;
  aspect-ratio: 1.2!important;
}

.detailproduct:hover {
  box-shadow: 4px 3px 9px 7px gray;
}

.detailproduct:hover img {
  overflow: visible;
  transform: scale(1.1);
}

.detailproduct:hover .primary-button {
  animation: moving 1s infinite;
}

.detailproduct:hover .name {
  color: var(--primary-color);
}


/* --------------------------------------------product-offer --------------------------------- ----------*/
.discounticon {
  position: relative;
  background: linear-gradient(270deg, yellow 0%, red 125%);
  fill: blue;
  padding: 2px 6px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  border-bottom-right-radius: 10px;
  height: 30px;
  width: 30px;
  display: flex;
  z-index: 1;
}

.offer {
  display: flex;
  overflow: hidden;
  position: relative;
  background: linear-gradient(90deg, yellow 0%, red 125%);
  color: blue;
  padding: 0;
  position: absolute;
  top: 0;
  left: 30px;
  width: 100%;
  transform: translateX(-110%);
  height: 30px;
  font-size: 12px;
  transition: all .5s ease-in-out;
  align-items: center;
  z-index: 0;
}

.singleproduct:hover .offer,
.detailproduct:hover .offer,
.orderitem:hover .offer,
.mcartitem:hover .offer {
  padding: 3px 0px 3px 0px;
  transform: translateX(0%);
}


.offer::before {
  transform: rotateZ(46deg);
  content: '';
  position: absolute;
  width: 40px;
  height: 85px;
  background: #ffc10783;
  top: -27px;
  left: 43px;
  animation: offeranimation 1s infinite;
}

@keyframes offeranimation {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}

/* ---------------------------------------------sns page ------------------------------------------------ */
.snsinput {
  max-width: 300px;
}

.sns .snsinputlist {
  border: 2px solid red;
  /* padding: 10px; */
  margin-top: 10px;
  width: 250px;
  max-height: 200px;
  opacity: 1;
  transition: all 5s ease;
  display: none;
}


.sns .list {
  width: 100%;
  border-bottom: 1px solid #0000003b;
  padding: 6px 7px;
  color: var(--secondry-color);
  transition: all .5s ease-in-out;
}

.sns .list:hover {
  color: red;
  transition: all .5s ease-in-out;
}

.sns .snsplus {
  color: red;
}

.sns .genere {
  border: 2.5px solid var(--primary-color);
  padding: 4px 12px;
  border-radius: 20px;
  background-color: #ff000036;
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
}


/* ---------------------------------------home ----------------------------------------------- */

.home {
  /* background-color: var(--primary-color); */
  /* background-color: white  ;  */
  padding: 40px 0px;
  /* background: linear-gradient(180deg, yellow 0%, red 125%); */
  background: linear-gradient(180deg, yellow 0%, #ff0000b3 125%);
}

.new-container {
  width: 93vw;
  margin: auto;
  max-width: 1320px;
}

.home img {
  animation: moving 4s infinite;
  margin-bottom: 50px;
  margin-top: 10px;
  max-height: 400px;
}

.home .m {
  color: var(--primary-color);
}

.home .k {
  color: rgb(0, 110, 0);
}

.nagar {
  margin-left: 175px;
  color: var(--secondry-color);
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes show-rojgar {
  0% {
    opacity: 0
  }

  10% {
    opacity: 0;
  }

  2% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  70% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.mangaoo {
  color: var(--secondry-color);
}

.typewriter {
  color: var(--secondry-color);
  margin-left: 90px !important;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  display: inline-block;
  animation: typing 7s steps(100) infinite;
}

.rojgar {
  margin-left: 40px;
  font-weight: bold;
  opacity: 0;
  animation: show-rojgar 7s steps(130) infinite;
  color: rgb(0, 110, 0);
}


@media screen and (max-width: 992px) {
  .nagar {
    margin-left: 135px;
  }

  .typewriter {
    margin-left: 85px !important;
  }
}

@media screen and (max-width: 768px) {
  .home img {
    margin-bottom: 30px;
  }

  .nagar {
    margin-left: 100px;
  }

  .typewriter {
    margin-left: 60px !important;
  }

  .rojgar {
    margin-left: 0px;
  }
}

@media screen and (max-width: 576px) {
  .home {
    padding: 15px;
  }

  .home img {
    max-height: 275px;
    margin-bottom: 30px;
    margin-top: 1px;
  }

  .mk {
    margin-left: 15vw;
  }

  .nagar {
    margin-left: 35vw;
  }

  .mangaoo {
    margin-left: 15vw;
  }

  .typewriter {
    margin-left: 30vw !important;
  }

  .rojgar {
    margin-left: 25vw;
  }
}

@media screen and (max-width: 476px) {
  .mk {
    margin-left: 9vw;
  }

  .nagar {
    margin-left: 35vw;
  }

  .mangaoo {
    margin-left: 9vw;
  }

  .typewriter {
    margin-left: 27vw !important;
  }

  .rojgar {
    margin-left: 21vw;
  }
}

@media screen and (max-width: 376px) {

  .mk {
    margin-left: 2vw;
  }

  .nagar {
    margin-left: 28vw;
  }

  .mangaoo {
    margin-left: 2vw;
  }

  .typewriter {
    margin-left: 15vw !important;
  }

  .rojgar {
    margin-left: 8vw;
  }
}

/* -------------------------------------------------discount products----------------------------------------- */
.discountproduct {
  position: relative;
  display: flex;
  height: 120px;
  min-width: 350px;
  align-items: center;
  transition: all .5s ease-in-out;
  cursor: pointer;
  border: 3px solid #ff9600;
  border-radius: 20px;
}

.discountproduct:hover {
  transform: translateY(-10px);
}

.discountproduct:hover .name {
  transition: all .6s ease-in-out;
  color: var(--primary-color);
}

.discountproduct img {
  width: 140px !important;
  height: 100px;
}

.discountproductdetail {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.discountproductdetail .name:hover {
  color: red;
  transition: all .5s ease-in-out;
}

.discount {
  display: flex;
  background: linear-gradient(180deg, yellow 0%, red 125%);
  position: absolute;
  top: 0;
  width: 121px;
  transform: rotateZ(318deg) translate(-34px, -8px);
  align-items: center;
  justify-content: center;
  color: blue;
}

.topCategory {
  display: flex;
  flex-wrap: wrap;
  column-gap: 55px;
  justify-content: center;
  
}

@media screen and (max-width: 768px) {
  .topCategory {
    column-gap: 38px;
  }
}

@media screen and (max-width: 576px) {
  .topCategory {
    column-gap: 22px;
  }
}


.topCategory .singleTopCategory {
  margin-bottom: 25px;
  cursor: pointer;
  width: 100px;
  height: 125px;
  text-align: center;
  border: 1px solid rgba(255, 166, 0, 0.498);
 display: flex;
 justify-content: center;
 flex-direction: column;
  border-radius: 10px;
  /* background: linear-gradient(180deg, yellow 0%, #ff0000b3 125%) ; */
}

.topCategory .singleTopCategory:hover h6{
   color: red;
   transition: all .5s ease-in-out;
}
.topCategory .singleTopCategory img {
   height: 80px;
   width: 80px;
   border-radius: 10px;
   margin : 5px 0px;
   transition: all .5s ease-in-out;
}

.topCategory .singleTopCategory:hover img {
  transform: scale(1.1);
  transition: all .5s ease-in-out;
}

/* -----------------------------------------------------features ----------------------------------------- */

.feature .feature-heading {
  text-align: center;
  margin-bottom: 10px;
  color: #018a01;
  text-decoration: underline;
}


.feature .feature-detail p {
  text-align: justify;
  color: #ffa000;
  /* background-color: #0080002e; */
}

.feature img {
  overflow: hidden!important;
  border-radius: 25px;
  width: 70%;
  max-width: 300px;
}

@media screen and (max-width: 992px) {
  .feature img {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .feature img {
    width: 65%;
    border-radius: 25px;
  }
}

@media screen and (max-width: 552px) {
  .feature img {
    width: 90%;
    border-radius: 25px;
    max-height: 100%;
  }

  .feature .my-container {
    width: 95%;
  }
}


/* --------------------------------------------- gallary --------------------------------------------------- */

.carousel {
  height: max-content;
}

.carousel-item .gallery-img {
  border: 5px solid rgba(0, 0, 0, 0.32);
  border-radius: 20px;
  width: 100%;
  max-height: 510px;
  overflow: hidden;
  aspect-ratio: 1.4!important;
}

.gallery .carousel-indicators {
  display: none;
}


.carousel-indicators button {
  height: 10px !important;
  width: 10px !important;
  background-color: green !important;
  border-radius: 50%;
}



/* --------------------------------------------------- records ---------------------------------------------- */
.records {
  background-color: #ebebeb;
  color: orange;
  fill: orange;
}

.records .single-record {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  width: 80%;
  border: 1px solid rgba(0, 0, 0, 0.135);
  border-radius: 20px;
  background-color: white;
  box-shadow: 10px 10px 10px -7px gray;
}

.records .single-record:hover svg {
  transform: translateY(-10px);
}

.records .single-record svg {
  transition: all .6s ease-in-out;
  width: 35px;
}

/* -----------------------------------------last ------------------------------------------------------------- */
.last .left {
  height: 280px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.last .left .last-div {
  flex-direction: column;
  /* gap: 20px; */
  width: 100%;
  align-items: center;
  height: 200px;
  justify-content: center;
}

.last .last-btn {
  cursor: pointer;
  color: orange;
  transition: all .6s ease-in-out;
}

.last .current-btn {
  cursor: pointer;
  background-color: orange;
  color: white;
  transition: all .6s ease-in-out;
}

.last .carousel {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.last .carousel-inner {
  width: 100%;
}

.last .singleappreview {
  display: flex;
  height: 180px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 20px;
  padding: 10px 10px;
}

.last .carousel-indicators {
  bottom: -15px;
  margin-right: auto;
  margin-left: auto;
}

.last .carousel-indicators button {
  height: 10px !important;
  width: 10px !important;
  background-color: green !important;
  border-radius: 50%;
}

.lazy-load-image-background {
  width: 100%;
  overflow: visible;
  display: flex!important;
  justify-content: center;
}

.lazy-load-image-background img {
  width: 100%;
  overflow: visible;
}


.truncate1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  /* Truncate after 3 lines */
}

.truncate2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /* Truncate after 3 lines */
}


.truncate3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  /* Truncate after 3 lines */
  /* -webkit-box: Specifies a flexible box layout model for the content.
-webkit-box-orient: vertical: Makes the box stack the content vertically.
-webkit-line-clamp: 3: Limits the content to 3 visible lines.
overflow: hidden: Ensures content that overflows will be hidden (truncated). */
}


.hindi {
  font-weight: 600;
  font-family: 'Eczar', serif;
}


/* ----------------------------------------------apniDukan ----------------------------------------*/
.dukandropdown {
  display: none;
  width: 250px;
  margin-top: 10px;
  border: 1.5px solid red;
  max-height: 200px;
  padding: 5px 5px;
}

/* .apnidukan svg {
  fill: red;
} */

.dukandropdown.show {
  display: block;
}

.dukandropdown .h6 {
  cursor: pointer;
  color: var(--secondry-color);
  transition: all .6s ease-in-out;
}

.dukandropdown .h6:hover {
  color: var(--primary-color);
}

.ownerimage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.bannerimage {
  width: 1100px;
  height: 530px;
  border-radius: 20px;
}

@media screen and (max-width: 991px) {
  .bannerimage {
    height: 430px;
  }
}

@media screen and (max-width: 768px) {
  .ownerimage {
    width: 130px;
    height: 130px;
  }

  .bannerimage {
    height: 330px;
  }
}

@media screen and (max-width: 578px) {
  .bannerimage {
    height: 250px;
  }
}


.dukandarnotificationdelete:hover {
  color: red;
}

/* .p7{
  font-family: ;
}
.p8{
  font-family: ;
} */

/* ---------------------------------------------------dukan customers ------------------------------------- */
.dukancustomer .addupdateoperation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: 2px solid green;
  border-radius: 20px;
  gap: 10px;
  margin: 10px auto auto;
}

.dukancustomer .addupdateoperation div {
  padding: 5px 15px;
  color: green;
  cursor: pointer;
}

.dukancustomer .addupdateoperation .active {
  border-radius: 20px;
  background-color: #008000d4;
  color: white;
  transition: color, background-color .5s ease-in-out;
}

.dukancustomer .update svg.d-block {
  position: absolute;
  top: 2px;
  right: 0px;
  fill: blue;
  cursor: pointer;
  background-color: white;
}

.dukancustomer .update svg.d-block:hover {
  fill: var(--primary-color);
  transition: all .5s ease-in-out;
}

.singlecustomer {
  position: relative;
  border: 2.5px solid gray;
  border-radius: 15px;
  padding: 10px;
}

.singlecustomer a:hover {
  color: var(--primary-color);
  transition: all .5s ease-in-out;
}

.customersearch svg.d-block {
  position: absolute;
  top: 2px;
  right: 0px;
  fill: blue;
  cursor: pointer;
  background-color: white;
}

.customersearch svg.d-block:hover {
  fill: var(--primary-color);
  transition: all .5s ease-in-out;
}


.updatecustomer svg {
  width: 40px;
}

/* ----------------------------------------------------dukan category-------------------------------------- */
.dukancategory .addupdateoperation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: 2px solid green;
  border-radius: 20px;
  gap: 10px;
  margin: 10px auto auto;
}

.dukancategory .addupdateoperation div {
  padding: 5px 15px;
  color: green;
  cursor: pointer;
}

.dukancategory .addupdateoperation .active {
  border-radius: 20px;
  background-color: #008000d4;
  color: white;
  transition: color, background-color .5s ease-in-out;
}


/* ------------------------- dukan map --------------------------- */
.dukanmap iframe {
  height: 400px !important;
}

@media screen and (max-width : 768px) {
  .dukanmap iframe {
    height: 300px !important;
  }
}


/* ----------------------------------------------dukan orders -------------------------------------- */
.dukanorder .operation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: 2px solid green;
  border-radius: 20px;
  gap: 10px;
  margin: 10px auto auto;
}

.dukanorder .operation div {
  padding: 5px 15px;
  color: green;
  cursor: pointer;
}

.dukanorder .operation .active {
  border-radius: 20px;
  background-color: #008000d4;
  color: white;
  transition: color, background-color .5s ease-in-out;
}

/* ---------------------------bill desk ------------------------- */
.billdesk {
  max-width: 1200px;
  /* width: 95%; */
  min-width: 880px;
  margin: auto;
}

.billdesk .bill {
  padding: 10px;
  border: 2.5px solid rgba(0, 0, 0, 0.196);
}


.billdesk .bill input,
.billdesk .bill textarea {
  flex: 1;
  border-bottom: none;
  padding: 0;
}

.billdesk .bill .fa-trash-can:hover {
  color: red;
  transition: all .5s ease-in;
}